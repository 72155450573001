import React, { useState } from "react";
import { cn } from "../lib/helpers";
import axios from "axios";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import * as styles from "./formMain.module.css";
import * as commonStyles from "../styles/common.module.css";

const isBrowser = typeof window !== "undefined";

const endpoints = {
  contact: "/.netlify/functions/sendEmail",
};

const theme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#CDAC6D",
    },
    secondary: {
      main: "#CDAC6D",
    },
  },
});

function ActionsMain() {
  let action = "";

  if (isBrowser) {
    const url = window.location.href;
    if (decodeURI(url.split("?")[1]).length > 0) {
      action = decodeURI(url.split("?")[1]);
    }
  }

  const [state, setState] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });
  const [error, setError] = useState({
    name: false,
    email: false,
    phone: false,
  });
  const [msg, setMsg] = useState("");

  const handleSuccess = () => {
    setMsg("Úspěšně odesláno.");
    setState({
      name: "",
      email: "",
      phone: "",
      message: "",
    });
  };
  const handleError = (msg) => {
    setMsg("Při odesílání nastala chyba, zkuste to prosím později.");
  };

  const submit = () => {
    setError({
      name: state.name === "",
      email: state.email === "",
      phone: state.phone === "",
    });

    if (state.name !== "" && state.email !== "" && state.phone !== "") {
      setMsg("");
      const data = {
        ...state,
        action,
      };
      axios.post(endpoints.contact, JSON.stringify(data)).then((response) => {
        if (response.status !== 200) {
          handleError();
        } else {
          handleSuccess();
        }
      });
    }
  };

  return (
    <div>
      <h1 className={cn(styles.headline, commonStyles.colorAccent, commonStyles.fontButlerMedium)}>
        Konzultace
      </h1>
      <div className={styles.formContainer}>
        {action !== "undefined" && (
          <h3
            className={cn(styles.title, commonStyles.colorDarkGray, commonStyles.fontButlerMedium)}
          >
            {`Jsme rádi, že máte zájem o: `}
            <span
              className={cn(commonStyles.colorAccent, commonStyles.fontButlerMedium)}
            >{`${action}.`}</span>
          </h3>
        )}
        <h3
          className={cn(styles.title, commonStyles.colorDarkGray, commonStyles.fontButlerMedium)}
        >{`Nechte nám prosím na sebe kontakt, naše recepční se Vám ozve.`}</h3>
        <form className={styles.form} noValidate autoComplete="off">
          <ThemeProvider theme={theme}>
            <TextField
              id="standard-name"
              label="Jméno"
              color="secondary"
              value={state.name}
              onChange={(e) => {
                setError({
                  ...error,
                  name: false,
                });
                setState({
                  ...state,
                  name: e.target.value,
                });
              }}
              error={error.name}
              helperText={error.name && "Prosím vyplntě vaše jméno"}
              required
            />
            <TextField
              id="standard-email"
              label="E-mail"
              value={state.email}
              onChange={(e) => {
                setError({
                  ...error,
                  email: false,
                });
                setState({
                  ...state,
                  email: e.target.value,
                });
              }}
              error={error.email}
              helperText={error.email && "Prosím vyplntě váš e-mail"}
              required
            />
            <TextField
              id="standard-email"
              label="Telefon"
              value={state.phone}
              onChange={(e) => {
                setError({
                  ...error,
                  phone: false,
                });
                setState({
                  ...state,
                  phone: e.target.value,
                });
              }}
              error={error.phone}
              helperText={error.phone && "Prosím vyplntě váš telefon"}
              required
            />
            <TextField
              id="standard-message"
              label="Vaše zpráva pro nás"
              multiline
              maxRows={8}
              value={state.message}
              onChange={(e) => {
                setState({
                  ...state,
                  message: e.target.value,
                });
              }}
            />
            <div className={styles.buttonContainer}>
              <Button variant="contained" color="primary" onClick={submit}>
                ODESLAT
              </Button>
            </div>
          </ThemeProvider>
        </form>
        {msg !== "" && (
          <div
            className={cn(
              commonStyles.colorAccent,
              commonStyles.fontReleway,
              commonStyles.letterSpacing1
            )}
          >
            {msg}
          </div>
        )}
        <a
          title="BBClinic Shop"
          className={cn(styles.link, commonStyles.colorAccent, commonStyles.fontButlerMedium)}
          target="_blank"
          href="https://shop.bbclinic.cz/konzultace"
        >
          Nebo si rovnou kupte poukaz na ošetření.
        </a>
      </div>
    </div>
  );
}

export default ActionsMain;
